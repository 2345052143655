import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import cx from 'classnames'
import map from 'lodash/fp/map'

import { withStyles } from '@material-ui/core/styles'
import { Button, Grid } from '@material-ui/core'
import {
	AccountGroup as AccountGroupIcon,
	CalendarToday as CalendarTodayIcon,
	Sort as SortIcon,
} from 'mdi-material-ui'

import { container } from '../sharedStyles'
import ColorSection from '../components/ColorSection'
import FeatureBox from '../components/FeatureBox'
import Hero from '../components/Hero'
import HeroTitle from '../components/HeroTitle'
import QuoteBox from '../components/QuoteBox'
import screenshotGmail02 from '../images/gmail-02.gif'
import Layout from '../components/Layout'

const features = [
	{
		icon: CalendarTodayIcon,
		title: 'Schedule',
		// content: 'When do I next need to think about this?',
	},
	{
		icon: AccountGroupIcon,
		title: 'Delegate and collaborate',
		// content: 'Does it need to be done by you? Could you get any help?',
	},
	{
		icon: SortIcon,
		title: '️Prioritize',
		// content: 'How important is it, really?',
	},
]

const quotes = [
	{
		author: 'Rachel Andrew',
		content: 'Your email is not your to-do list!',
		position: 'center',
	},
	{
		author: 'Anon',
		content: 'Email is a to-do list— made by other people.',
		position: 'center',
	},
	{
		author: 'Chris Sacca',
		content: "Email is a task list that's created for you by someone else.",
		position: 'center',
	},
]

const styles = theme => ({
	root: {},
	mainScreenshot: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(),
		position: 'relative',
		alignItems: 'center',
	},
	screenshot: {
		boxShadow: theme.shadows[20],
		borderRadius: 2,
		display: 'block',
		transition: theme.transitions.create(),
		width: '90%',
		'&:hover': {
			boxShadow: theme.shadows[24],
			transform: 'scale( 1.01 )',
		},
		[theme.breakpoints.up('lg')]: {
			borderRadius: 8,
			width: '70%',
		},
		[theme.breakpoints.only('md')]: {
			borderRadius: 6,
			width: '70%',
		},
		[theme.breakpoints.only('sm')]: {
			borderRadius: 4,
			width: '80%',
		},
	},
	container: container(theme),
	heading: {
		fontWeight: 700,
	},
	marginBottom: {
		marginBottom: theme.spacing(8),
	},
})

class NotFound extends Component {
	static propTypes = {
		classes: PropTypes.object,
	}

	render() {
		const { classes } = this.props

		const images = [{ src: screenshotGmail02, alt: 'Animated GIF' }]

		return (
			<Layout isLandingPage>
				<Helmet>
					<title>Create tasks directly from Gmail</title>
				</Helmet>
				<section className={classes.root}>
					<Hero className={classes.marginBottom}>
						<div className={classes.marginBottom}>
							<HeroTitle
								title={'Create tasks directly from Gmail'}
							/>
							{/* <Typography align="center" variant="h5">
							Don't leave your tasks in your inbox.
						</Typography> */}
						</div>

						<Grid
							container
							className={classes.marginBottom}
							spacing={2}
							justify={'center'}
						>
							{images.map((props, index) => (
								<Grid item key={index} sm={'auto'} xs={12}>
									<div className={classes.mainScreenshot}>
										<img
											src={props.src}
											alt={props.alt}
											className={classes.screenshot}
										/>
									</div>
								</Grid>
							))}
						</Grid>

						<Grid container justify={'center'}>
							<Grid item>
								<Button
									className={classes.button}
									color={'primary'}
									href={
										'https://chrome.google.com/webstore/detail/tyto-for-gmail/dfggekmdbcnfaadnaofeemohnflncdhj'
									}
									variant={'contained'}
								>
									Download from Chrome Web Store
								</Button>
							</Grid>
						</Grid>
					</Hero>

					<section
						className={cx(classes.container, classes.marginBottom)}
					>
						<FeatureBox
							features={features}
							heading={'Touch each email only once'}
						/>
					</section>

					<ColorSection
						title={"Don't leave your tasks in your inbox."}
					>
						<Grid container spacing={2}>
							{map(quote => (
								<Grid item key={quote.content} md={4} xs={12}>
									<QuoteBox {...quote} />
								</Grid>
							))(quotes)}
						</Grid>
					</ColorSection>
				</section>
			</Layout>
		)
	}
}

export default withStyles(styles)(NotFound)
