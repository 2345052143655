import React, { Component } from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/fp/map'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const styles = theme => ( {
	feature: {
		marginBottom: theme.spacing(3),
		marginTop: theme.spacing(3),
		maxWidth: theme.spacing(8 * 5),
		textAlign: 'center',
	},
	icon: {
		color: theme.palette.common.white,
		height: theme.spacing(5),
		width: theme.spacing(5),
	},
	iconWrapper: {
		alignItems: 'center',
		backgroundColor: theme.palette.secondary.main,
		borderRadius: theme.spacing(4),
		display: 'inline-flex',
		height: theme.spacing(8),
		justifyContent: 'center',
		marginBottom: theme.spacing(3),
		width: theme.spacing(8),
	},
} )

class FeatureBox extends Component {
	static defaultProps = {
		features: [],
	}

	static propTypes = {
		classes: PropTypes.object,
		features: PropTypes.arrayOf(
			PropTypes.shape( {
				content: PropTypes.string,
				icon: PropTypes.any,
				title: PropTypes.string,
			} )
		),
		heading: PropTypes.string,
	}

	mapFeatures = map( feature => {
		const { classes } = this.props
		const { content, icon: FeatureIcon, title } = feature
		return (
			<Grid item className={classes.feature} key={title} sm={4} xs={12}>
				<div className={classes.iconWrapper}>
					<FeatureIcon className={classes.icon} />
				</div>
				<Typography align="center" gutterBottom variant="h6">
					{title}
				</Typography>
				<Typography align="center" variant="body1">
					{content}
				</Typography>
			</Grid>
		)
	} )

	render() {
		const { features, heading } = this.props
		return (
			<Grid container justify="center" spacing={3}>
				{heading && (
					<Grid item xs={12}>
						<Typography align="center" gutterBottom variant="h4">
							{heading}
						</Typography>
					</Grid>
				)}
				{this.mapFeatures( features )}
			</Grid>
		)
	}
}

export default withStyles( styles )( FeatureBox )
