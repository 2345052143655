import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { container } from '../sharedStyles'

const styles = theme => ({
	root: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		paddingBottom: theme.spacing(8),
		paddingTop: theme.spacing(8),
	},
	container: container(theme),
	heading: {
		marginBottom: theme.spacing(3),
	},
})

class ColorSection extends Component {
	static propTypes = {
		children: PropTypes.any,
		classes: PropTypes.object,
		title: PropTypes.string,
	}

	render() {
		const { children, classes, title } = this.props
		return (
			<div className={classes.root}>
				<div className={classes.container}>
					<Typography
						className={classes.heading}
						align="center"
						color="inherit"
						gutterBottom
						variant="h5"
					>
						{title}
					</Typography>
					{children}
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(ColorSection)
